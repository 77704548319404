.achievement {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3rem;
}

.achievement-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 0.4;
    width: 90%;
}

.achievement-body h1 {
    font-family: var(--primaryFont);
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
}

.achievement-body h4 {
    font-size: 1.75rem;
    font-family: 'Caveat', cursive;
    font-weight: 400;
}

.achievement-cards {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 5%;
    width: 100%;
    flex: 0.6;
}

.achievement-card {
    display: flex;
    flex-direction: row;
    align-items: center; /* Align items to center to prevent overlap */
    justify-content: space-between;
    padding: 1rem;
    border-radius: 20px;
    margin-bottom: 1rem;
    width: 100%;
    position: relative;
    overflow: hidden;
    min-height: 150px; /* Ensure a minimum height to avoid overlapping */
}

.achievecard-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%; /* Adjust width to prevent content from overlapping */
    padding-left: 1rem; /* Add padding on the left side */
    box-sizing: border-box; /* Include padding in width calculation */
}

.achievecard-details1 h2 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.achievecard-details1 p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.achievecard-details2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    padding-left: 1rem; /* Add padding on the left side */
    white-space: nowrap;
    margin-top: 0.5rem;
}

.achievecard-details2 h5 {
    margin: 0;
    font-size: 1rem;
    color: inherit;
}

.achievecard-field {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 0.9rem;
}

.achievecard-field h5 {
    margin: 0;
}

.achievecard-field svg {
    margin-right: 6px;
}

.achievecard-imgcontainer {
    width: 25%; /* Adjust width to ensure image fits on the right */
    display: flex;
    justify-content: flex-end; /* Keep the image on the right */
    align-items: center;
    box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}

.achievecard-imgcontainer > img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

/* For medium to large screens */
@media (min-width: 992px) and (max-width: 1380px) {
    .achievement {
        flex-direction: row;
        align-items: flex-start;
        padding: 2rem;
    }

    .achievement-body h1 {
        font-size: 3.1rem;
    }

    .achievement-cards {
        padding: 1rem;
        margin-left: 0;
        width: 100%;
        flex: 1;
    }

    .achievement-card {
        flex-direction: row;
        align-items: center;
        min-height: 150px; /* Ensure minimum height for larger screens */
    }

    .achievecard-content {
        width: 60%; /* Adjust width for larger screens */
        padding-left: 1rem;
    }

    .achievecard-imgcontainer {
        width: 25%; /* Adjust image container width */
    }
}

/* For tablets and small laptops */
@media screen and (max-width: 992px) {
    .achievement {
        flex-direction: column;
        align-items: center;
        padding: 2rem;
    }

    .achievement-body {
        width: 100%;
        align-items: center;
    }

    .achievement-body h1 {
        font-size: 2.5rem;
        text-align: center;
    }

    .achievement-cards {
        margin-left: 0;
        padding: 1rem 0;
        width: 100%;
        margin-top: 1rem;
    }

    .achievement-card {
        flex-direction: column; /* Stack items vertically on smaller screens */
        align-items: flex-start;
        min-height: fit-content;
    }

    .achievecard-content {
        width: 100%;
        padding-left: 0; /* Remove padding on smaller screens */
    }

    .achievecard-details2 {
        width: 100%;
        margin-top: 0.5rem;
        padding-left: 0; /* Remove padding on smaller screens */
    }

    .achievecard-imgcontainer {
        width: 100%; /* Stack image at the bottom */
        justify-content: center; /* Center the image on smaller screens */
        margin-top: 1rem;
    }

    .achievecard-imgcontainer > img {
        width: 100px; /* Adjust image size for smaller screens */
        height: auto;
    }
}

/* For mobile devices */
@media screen and (max-width: 600px) {
    .achievement {
        padding: 1rem;
    }

    .achievement-body h1 {
        font-size: 2rem;
        text-align: center;
    }

    .achievement-body h4 {
        font-size: 1.5rem;
        text-align: center;
    }

    .achievement-cards {
        width: 100%;
    }

    .achievement-card {
        flex-direction: column;
        align-items: flex-start;
        min-height: fit-content;
    }

    .achievecard-content {
        width: 100%;
        padding-left: 0; /* Remove padding for smaller screens */
    }

    .achievecard-details1 p {
        font-size: 0.95rem;
    }

    .achievecard-imgcontainer {
        width: 100%;
        justify-content: center;
        margin-top: 1rem;
    }

    .achievecard-imgcontainer > img {
        width: 100px; /* Adjust image size */
        height: auto;
    }
}