.landing {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing--container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.landing--img {
  --img-size: 400px;
  position: absolute;
  left: 35%;
  transform: translateX(-50%);
  width: var(--img-size);
  /* Use the calc() function to add 100 pixels to the --img-size variable */
  height: calc(var(--img-size) + 100); 
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s;
}

.landing--container-left {
  flex: 35%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.lcl--content {
  margin: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.landing--social {
  font-size: 35px;
  margin: 0 1rem;
  transition: transform 0.5s;
}

.landing--social:hover {
  transform: scale(1.2);
}

.landing--container-right {
  flex: 65%;
  height: 100%;
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.lcr--content {
  width: 45%;
  margin-right: 7rem;
  font-family: var(--primaryFont);
  font-style: normal;
}

.lcr--content h6 {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: -0.85rem;
  opacity: 0.8;
}

.lcr--content h1 {
  font-weight: 600;
  font-size: 3.25rem;
  line-height: 110%;
  margin: 1rem 0;
}

.lcr--content p {
  margin-top: 1.45rem;
  font-weight: 500;
  font-size: 1.15rem;
  opacity: 0.7;
}

.lcr-buttonContainer {
  margin-top: 2rem;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "var(--primaryFont)", sans-serif;
}

@media (max-width: 1100px) {
  .landing--img {
    --img-size: 350px;
  }
  .lcl--content {
    margin: 2rem;
  }

  .lcr--content h6 {
    font-size: 1.1rem;
  }

  .lcr--content h1 {
    font-size: 3rem;
  }

  .lcr--content p {
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1rem;
  }

  .lcr-buttonContainer {
    margin-top: 1rem;
    flex-direction: column;
    width: 200px;
    height: 120px;
    align-items: flex-start;
  }
}

@media (max-width: 900px) {
  .landing--img {
    --img-size: 300px;
  }

  .landing--social {
    font-size: 30px;
  }
  .lcr--content {
    width: 60%;
    margin-top: 10%;
    margin-right: 6%;
  }

  .lcr-buttonContainer {
    margin-top: 1rem;
    flex-direction: column;
    width: 200px;
    height: 120px;
    align-items: flex-start;
  }
  .lcr-buttonContainer button:nth-child(2) {
    display: none;
  }
}

@media (max-width: 600px) {
  .landing--container {
    flex-direction: column;
    justify-content: space-between;
  }
  .landing--img {
    --img-size: 200px;
    left: 50%;
    top: 30%;
    border: 8px solid #eaeaea;
    transform: translate(-50%, -50%);
  }
  .landing--container-left {
    flex: initial;
    width: 100%;
    height: 30%;
  }
  .landing--social {
    display: none;
  }
  .landing--container-right {
    box-sizing: border-box;
    padding: 0 2rem;
    flex: initial;
    height: 70%;
    width: 100%;
    background-color: #eaeaea;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
  }
  .lcr--content {
    margin-top: 20%;
    margin-right: initial;
    width: 100%;
  }
  .lcr--content h6 {
    font-size: 1rem;
    text-align: center;
  }
  .lcr--content h1 {
    font-size: 2.5rem;
    text-align: center;
  }
  .lcr--content p {
    font-size: 0.95rem;
    text-align: center;
    margin: 0 auto;
    width: 90%;
  }
  .lcr-buttonContainer {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lcr-buttonContainer button {
    margin-top: -20%;
  }
}

@media (max-width: 500px) {
  .lcr--content {
    margin-top: 35%;
  }
}
@media (max-width: 370px) {
  .lcr--content {
    margin-top: 60%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  .landing {
    height: 100%;
  }

  .landing--img {
    --img-size: 150px;
    left: 50%;
    top: 20%;
  }
}
